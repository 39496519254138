export const WORDS = [
  'count',
  'notes',
  'bonus',
  'penny',
  'month',
  'trade',
  'trial',
  'payer',
  'graph',
  'cycle',
  'yield',
  'limit',
  'cloud',
  'sales',
  'tasks',
  'costs',
  'earns',
  'total',
  'wages',
  'fixed',
  'books',
  'error',
  'debit',
  'chaps',
  'level',
  'risks',
  'coins',
  'terms',
  'ratio',
  'check',
  'table',
  'cyber',
  'macro',
  'price',
  'paper',
  'audit',
  'email',
  'train',
  'gains',
  'loans',
  'focus',
  'round',
  'money',
  'order',
  'types',
  'award',
  'legal',
  'debit',
  'money',
  'goals',
  'bonds',
  'pound',
  'petty',
  'asset',
  'funds',
  'study',
  'bonds',
  'repay',
  'debts',
  'debit',
  'minus',
  'pound',
  'added',
  'goods',
  'shift',
  'taxes',
  'fraud',
  'calls',
  'merit',
  'range',
  'crime',
  'stock',
  'entry',
  'exams',
  'bribe',
  'units',
  'ethic',
  'banks',
  'micro',
  'tutor',
  'clark',
  'issue',
  'grade',
  'learn',
  'piece',
  'solve',
  'draft',
  'rates',
  'slips',
  'texts',
  'gross',
  'value',
  'chart',
]